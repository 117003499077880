/* eslint-disable */
export default function registerSwitcher($, apower) {
  //switcher, used at video grabber index page
  $.register('switcher', {

    selector: '.switcher',

    ref: {
      btnWrap: '.switcher-btn',
      tabWrap: '.switcher-tab',
      btns: '.switcher-btn > li',
      tabs: '.switcher-tab > li',
    },

    props: ['mobile-collapse', 'interval', 'apply-class-to', 'toggle-active-to', 'mouseenter-collapse', 'no-height'],

    index: -1,

    interval: 0,

    applyClassTo: '',

    applyClassToEl: null,

    toggleActiveTo: '',

    mobileCollapse: false,

    mouseenterCollapse: false,

    noHeight: false,

    intervals: null,

    isMouseEntered: false,

    direction: -1,	//it means when beginning, we just slide the first one to left, and more likely we will slide to left later

    init: function () {
      this.initBtnList()
      this.initActive()
      this.initIntervals()
      this.initApplyClass()

      this.resetInterval()

      if(!this.noHeight){
        this.fitTabHeight()
      }

      this.btns.$on('click', this.onClickBtn, this)
      this.tabs.$on('click', this.onClickTab, this)
      this.el.attr('data-slider-index', this.index)
    },

    initBtnList: function () {
      if (this.btns.length < this.tabs.length) {
        for (var i = this.btns.length; i < this.tabs.length; i++) {
          var tabLiName = this.tabs.eq(i)[0].className.replace(/\s*(?:-?active)\s*/g, '')

          if(tabLiName){
            var className = tabLiName + '-btn'
            this.btnWrap.append('<li class=' + className + '></li>')
          }
          else{
            this.btnWrap.append('<li></li>')
          }
        }

        this.applyRef('btns')
      }
    },

    initActive: function () {
      var index = this.tabs.filter('.active').index()
      if (index === -1) {
        index = 0
      }

      this.tabs.css('transition', 'none')
      this.setIndex(index, true)
      this.tabs.css('transition', '')
    },

    initIntervals: function () {
      if (this.interval) {
        this.intervals = []

        for (var i = 0; i < this.tabs.length; i++) {
          this.intervals[i] = Number(this.tabs.eq(i).data('interval')) || this.interval
        }

        if(!this.mouseenterCollapse){
          this.el.$on('mouseenter', this.onMouseEnter, this)
          this.el.$on('mouseleave', this.onMouseLeave, this)
        }
      }
    },

    onMouseEnter: function (e) {
      this.isMouseEntered = true
      this.el.addClass('slider-lock')

      if(this.timeoutId){
        clearTimeout(this.timeoutId)
        this.timeoutId = 0
      }
    },

    onMouseLeave: function (e) {
      this.isMouseEntered = false
      this.el.removeClass('slider-lock')
      this.el.addClass('slider-before-unlock')
      this.el[0].offsetWidth
      this.el.removeClass('slider-before-unlock')

      this.resetInterval()
    },

    initApplyClass: function () {
      if (this.applyClassTo) {
        this.applyClassToEl = $(this.applyClassTo)
        this.applyClassToEl = this.applyClassToEl.length ? this.applyClassToEl : null
      }

      this.handleClassApplying()
    },

    timeoutId: 0,

    resetInterval: function () {
      if (!this.interval) {
        return
      }

      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
      }

      if (this.intervals) {
        this.timeoutId = setTimeout(this.nextTab.bind(this), this.intervals[this.index])
      }
    },

    stopInterval: function () {
      clearTimeout(this.timeoutId)
      this.timeoutId = 0
    },

    fitTabHeight: function () {
      var tab = this.tabs.eq(this.index)

      if (tab.length > 0 && tab[0].offsetHeight !== this.tabWrap.height() && tab[0].offsetHeight > 0) {
        this.tabWrap.height(tab[0].offsetHeight)
      }
    },

    onClickBtn: function (e) {
      var index = this.btns.indexOf(e.currentTarget)
      this.setIndex(index)
    },

    onClickTab: function (e) {
      var index = $(e.currentTarget).index()
      this.setIndex(index)
    },

    nextTab: function () {
      var index = (this.index + 1) % this.tabs.length
      this.setIndex(index)
    },


    lastAppliedClass: '',

    setIndex: function (index, isFirstTime) {
      if (index === this.index) {
        return
      }

      this.direction = index > this.index ? 1 : -1

      var oldIndex = this.index
      var lastTabEl = this.tabs.eq(oldIndex)
      lastTabEl.$trigger('tabinactive', lastTabEl)

      var tabEl = this.tabs.eq(index)
      var len = this.tabs.length
      this.index = index
      var nextIndex = (index + 1 + len) % len
      var prevIndex = (index - 1 + len) % len


      //clear old state
      this.btns.removeClass('active').eq(index).addClass('active')
      this.tabs.removeClass('last-active').removeClass('prev-active').removeClass('next-active')

      //对于 slider-pan, 如果从 4 切换到 2, 那么应当 4 被设置为 next-active, 如果从 2 切换到 4, 2 被设置为 next-active
      if (!isFirstTime && this.el.hasClass('slider-pan')) {
        this.tabs.css('transition', 'none')

        if (this.direction === 1) {
          this.tabs.eq(index).addClass('next-active')
        }
        else {
          this.tabs.eq(index).addClass('prev-active')
        }

        //rerendering
        this.tabs.each(function (index, el) {
          el.offsetWidth
        })

        this.tabs.css('transition', '')

        //set animation end state
        this.tabs.eq(index).removeClass('prev-active').removeClass('next-active')

        if (this.direction === 1) {
          this.tabs.eq(oldIndex).addClass('prev-active')
        }
        else {
          this.tabs.eq(oldIndex).addClass('next-active')
        }
      }
      //对于其他类型, 当选了某个 tab, 前面一个一定是 prev-active, 后面一个一定是 next-active
      else {
        if (this.tabs.length > 2) {
          this.tabs.eq(prevIndex).addClass('prev-active')
        }

        this.tabs.eq(nextIndex).addClass('next-active')
      }

      this.tabs.eq(oldIndex).removeClass('active').addClass('last-active')
      this.tabs.eq(index).addClass('active')


      this.el.attr('data-slider-index', index)	//control btn style when slide to different screen

      if(!this.noHeight){
        this.fitTabHeight()
      }

      if (!this.isMouseEntered) {
        this.resetInterval()
      }

      this.onSetIndex(index)

      this.trigger('toggle', tabEl)
      tabEl.$trigger('tabactive', tabEl)	//it looks very like tab, so use tabactive

      this.handleClassApplying()
      this.handleActiveApplying()

      var prevIndex = (index - 1 + this.tabs.length) % this.tabs.length
      var nextIndex = (index + 1) % this.tabs.length
    },

    initActiveClass: function () {
      if(this.toggleActiveTo){
        this.toggleActiveToEl = $(this.toggleActiveTo)
        this.toggleActiveToEl = this.toggleActiveToEl.length ? this.toggleActiveToEl : null
      }

      this.handleActiveApplying()
    },

    handleActiveApplying: function () {
      if(this.toggleActiveToEl){
        var child = this.toggleActiveToEl.children()

        child.removeClass('active')
        child.eq(this.index).addClass('active')
      }
    },

    handleClassApplying: function () {
      if (this.applyClassToEl) {
        if (this.lastAppliedClass) {
          this.applyClassToEl.removeClass(this.lastAppliedClass)
          this.btnWrap.removeClass(this.lastAppliedClass)
          this.lastAppliedClass = ''
        }

        var className = this.tabs.eq(this.index).attr('data-class-to-apply')

        //使用老的 className 加 '-style' 的方法
        if (!className) {
          className = this.tabs[this.index].className.replace(/\s*(?:\w+-)?active/g, '').trim()

          if (className) {
            className += '-style'
          }
        }

        if (className) {
          this.lastAppliedClass = className
          this.applyClassToEl.addClass(className)
          this.btnWrap.addClass(this.lastAppliedClass)
        }
      }
    },

    onSetIndex: function () {},
  })
}