/* eslint-disable */
import registerSwitcher from './switcher'
export default function registerSlider($, apower) {
  registerSwitcher($, apower)
  //slider, for index banner or product screenshots exhibition
//can add a "slider-pan" class to make page slide left or right
//can add a "slider-mobile-flatten" class to make sure it becomes flatten on mobile devices
  $.register('slider', 'switcher', {

    selector: '.slider',

    props: ['no-pan', 'slider-group'],

    noPan: false,

    sliderGroup: false,

    ref: {
      bodyWrap: '.slider-body',
      btnWrap: '.slider-btn',
      tabWrap: '.slider-tab',
      btns: '.slider-btn > li',
      tabs: '.slider-tab > li',
      prevEl: '.slider-prev',
      nextEl: '.slider-next',
      leftEl: '.slider-img-left',
      rightEl: '.slider-img-right',
    },

    init: function () {
      if(this.sliderGroup){
        this.initSliderGroup()
      }
      this.initCollapseTabs()

      this.callSuper('switcher')

      this.initActiveClass()
      this.handlePanStyle()

      this.prevEl.$on('click', this.prevTab, this)
      this.nextEl.$on('click', this.nextTab, this)
      this.leftEl.$on('click', this.prevTab, this)
      this.rightEl.$on('click', this.nextTab, this)

      this.tabWrap.onPan(this.onPan.bind(this))

      var fitDir = this.tabWrap.dir('fit')
      if (fitDir) {
        fitDir.fitDirection = 'h'
      }
    },

    handlePanStyle: function () {
      if (!this.el.hasClass('slider-pan') && this.btns.length && !this.noPan) {
        $.defer(function () {
          $.onToggleMobile(function () {
            this.el.addClass('slider-pan')
          }, function () {
            this.el.removeClass('slider-pan')
          }, this)
        }, this)
      }
    },

    initCollapseTabs: function () {
      if ($(window).width() < 768 && this.mobileCollapse) {
        this.tabs.each(function (index, li) {
          var li = $(li)
          var lastChild = li.children(':last')
          li.after($('<li></li>').append(lastChild))
        })

        this.applyRef()
      }
    },

    initSliderGroup: function () {
      var div = this.tabs.children()
      var l = div.length
      var w = div.width()
      var limitWidth = this.el.width()

      if( w * l <= limitWidth ){
        return
      }
      else{
        var ave = Math.floor( limitWidth / w )
        var length = Math.ceil( ( l - ave ) / ave )

        for(var j = 0; j < length; j ++ ){
          this.tabWrap.append('<li></li>')

          for(var i = 0; i < l - ave * (j + 1); i ++ ){
            var divEl = div.eq(ave * (j + 1) + i)
            this.tabWrap.find('>li:last-child').append(divEl)
          }
        }
      }

      this.applyRef()
    },

    prevTab: function () {
      var index = (this.index - 1 + this.tabs.length) % this.tabs.length
      this.setIndex(index)
    },

    onPan: function (dir) {
      if (dir === 'l') {
        this.prevTab()
      }
      else if(dir === 'r') {
        this.nextTab()
      }
    }
  })
}