
import "@/styles/pages/index/index.css";
import registerSlider from "/framework/components/slider";
          // window.installFramework = window.installFramework || Function.prototype
          installFramework(function(Vue) {
            // 入口文件

new Vue({
  created($, apower, LazyLoad) {
    registerSlider($, apower);
    new LazyLoad({
      el: ".classify-obj",
      onScroll: 100,
      componentFactory() {
        // framework-loader 对 dynamic import 作了处理， 此处开头使用!号避免 dynamic import 提升。
        !import("@/styles/pages/index/classify.css");
        init();
      },
    });
  },
});
// 768-1024
var isMidWidth = false;
// 320-768
var isMobile = false;

function initEvent() {
  $(".classify-obj .class-item").on("mouseenter", function () {
    // $(this).removeClass('an-active').addClass('active').siblings().removeClass('active').addClass('an-active')
    // $('.classify-obj .class-item').removeClass('active').removeClass('an-active');
    if (isMobile) {
      return;
    }
    reSetClassify();
    if (isMobile) {
      $(this).addClass("active");
    } else {
      $(this).addClass("active").siblings().addClass("an-active");
    }
  });
  $(".classify-obj .class-item").on("click", function () {
    if (!isMobile) {
      return;
    }
    reSetClassify();
    $(this).addClass("active");
  });
  $(".classify-obj").on("mouseleave", function () {
    $(".classify-obj .class-item")
      .removeClass("an-active")
      .removeClass("active");
  });
  $(window).resize(checkWidth);
}

function reSetClassify() {
  $(".classify-obj .class-item").removeClass("active").removeClass("an-active");
}

function checkWidth() {
  var bodyWidth = window.innerWidth;
  if (bodyWidth > 768 && bodyWidth < 1024) {
    isMidWidth = true;
    isMobile = false;
  } else if (bodyWidth <= 768) {
    isMobile = true;
    isMidWidth = false;
  } else if (bodyWidth >= 1024) {
    isMidWidth = false;
    isMobile = false;
  }
  if (isMidWidth) {
    setTwoLine();
  } else {
    setOneLine();
  }
}

function setTwoLine() {
  var classifyContainerNum = $(".classify-container").length;
  var firstContainerItem = $($(".classify-container")[0]).find(".class-item");
  var firstContainerItemNum = firstContainerItem.length;

  if (classifyContainerNum == 2 && firstContainerItemNum == 2) {
    return;
  }
  if (classifyContainerNum == 1) {
    var $container = $("<div>", { class: "classify-container" });
    $container.prepend(firstContainerItem[2]);
    $container.append(firstContainerItem.last());
    $(".classify-obj .wrapper").append($container);
    reSetClassify();
    return;
  }
}

function setOneLine() {
  var classifyContainerNum = $(".classify-container").length;
  var firstContainerItem = $($(".classify-container")[0]).find(".class-item");
  var firstContainerItemNum = firstContainerItem.length;
  var lastContainerItem = $($(".classify-container")[1]).find(".class-item");
  var lastContainerItemNum = lastContainerItem.length;

  if (classifyContainerNum == 1 && firstContainerItemNum == 4) {
    return;
  }
  if (classifyContainerNum == 2 && lastContainerItemNum > 0) {
    //$($('.classify-container')[0])
    var first = lastContainerItem.first();
    var last = lastContainerItem.last();
    $($(".classify-container")[0]).append(first);
    $($(".classify-container")[0]).append(last);
    $($(".classify-container")[1]).remove();
    reSetClassify();
  }
}

function init() {
  initEvent();
  checkWidth();
}

          })
        